define(['app', 'siteObj', '$console', '$window'], (app, siteObj, $console, $window) => {
  const yotiVerification = () =>{
    const component = {};
    const basketPage = document.documentElement;

    component.config = {
      selectors: {
        yotiModalClose: '.yotiVerification_iFrame-close',
        yotiButton: '[data-js-element=basket_triggerYoti]'
      },
      classNames: {
        showYotiContainer: 'yotiVerification-show',
        yotiFrame: '.yotiVerificationFrame',
        popUpLock: 'js-popup-lock',
        verifyAgeButton: '.yotiVerification_ageVerificationButton',
        showAgeButton: 'yotiVerification_ageVerificationButton-show',
        ageVerificationError: '.ageVerification_error',
        displayAgeError: 'ageVerification_error-show',
        ageVerificationSuccess: '.ageVerification_success',
        displayAgeSuccess: 'ageVerification_success-show',
        checkoutButton: 'button[class*="basketButton"]',
        checkoutForm: '.checkoutStartForm',
        paymentOptions: '.presentationalPaymentTypes_paymentTypeLink',
        successMessageContainer: '.responsiveBasket_discountAlert-displayMessage',
        yotiCheckout: '.ageVerification_success a',
        paymentTypes: '.presentationalPaymentTypes'
      },
      settings: {
        cookieName: 'Verification',
        yotiBaseUrl: 'age.yoti.com', // convert this to a siteObj variable
        yotiResultUrl: 'age-verification-results.yoti',
        url_string: '',
        url: '',
        sdkId: '',
        sessionId: null,
        yotiSrc: '',
      }
    };

    component.init = function (element) {
      component.element = element;
      component.triggerConditions();
      component.yotiFrame = component.element.querySelector(component.config.classNames.yotiFrame);
    };

    // get sessionId from URL if it exists otherwise set it to null
    component.getSessionId = () => {
      component.config.settings.url = new URL($window.location.href);
      component.config.settings.sessionId = {sessionId: component.config.settings.url.searchParams.get('sessionId')};
    };

    component.checkForSessionIdAndGetResult = () => {
      if (component.config.settings.sessionId.sessionId !== null) {

        app.ajax.post({
          url: `/${component.config.settings.yotiResultUrl}`,
          send: component.config.settings.sessionId,
          requestHeader: {
            header: 'Content-Type',
            value: 'application/json'
          },
          success: component.handleIframeEvent,
          error: component.yotiResultErrorHandler
        })
      } else {
        component.getCookieValue() === false ? component.errorFlow() : component.successFlow();
      }
    }

    component.yotiResultErrorHandler = () => {
      siteObj.debugFlag && $console.error(`Failed to retrieve results for id: ${component.config.settings.sessionId}`);
    }

    component.triggerConditions = () => {
      component.getSessionId();
      component.checkForSessionIdAndGetResult();
    }

    component.getCookieValue = () => {
      return document.cookie.includes(component.config.settings.cookieName) && document.cookie.split('; ').find(cookie => cookie.startsWith('Verification')).includes('COMPLETE');
    }

    component.getYotiSession = () => {
      app.ajax.post({
        url: '/create-session.yoti',
        success: component.sessionSuccessResponse,
        error: component.yotiSessionError
      })
    }

    component.yotiSessionError = (response) => {
      let msg = '';
      switch (response.status) {
        case 500:
          msg = response.responseJSON.ExceptionMessage;
          break;
        default:
          msg = 'Status: ' + response.status;
          msg += '\n' + 'Error Message: ' +
          response.statusText;
          break;
      }
      $console.error(msg);
    }

    component.sessionSuccessResponse = (response) => {
      let data = JSON.parse(response);
      component.config.settings.url_string = data.verificationUrl;
      component.config.settings.url = new URL(component.config.settings.url_string);
      component.config.settings.sdkId = component.config.settings.url.searchParams.get('sdkId');
      component.config.settings.sessionId = component.config.settings.url.searchParams.get('sessionId');
      let baseUrl = `https://${component.config.settings.yotiBaseUrl}`
      if (siteObj.config.defaultVerificationMethod) {
        baseUrl = `https://${component.config.settings.yotiBaseUrl}/${siteObj.config.defaultVerificationMethod}`
      }

      component.config.settings.yotiSrc = `${baseUrl}?sdkId=${component.config.settings.sdkId}&sessionId=${component.config.settings.sessionId}`;

      // future change check method here (redirect/iframe etc)
      component.redirectToYoti();
    }

    component.redirectToYoti = () => {
      $window.location.href = component.config.settings.yotiSrc;
    }

    // move the iframe stuff to it's own file
    component.showYotiModal = (response) => {
      let data = JSON.parse(response);
      if(siteObj.config.defaultVerificationMethod){
        //direct to specific verification method
        component.yotiFrame.src = component.config.settings.yotiSrc;
      } else {
        //load in dashboard
        component.yotiFrame.src = data.verificationUrl;
      }
      component.element.classList.add(component.config.classNames.showYotiContainer);
      basketPage.classList.add(component.config.classNames.popUpLock);
      component.listenToFrame();
      component.yotiModalClose = component.element.querySelector(component.config.selectors.yotiModalClose);
      component.yotiModalClose.addEventListener('click', component.closeYotiModal);
    }


    component.closeYotiModal = () => {
      basketPage.classList.remove(component.config.classNames.popUpLock);
      component.element.classList.remove(component.config.classNames.showYotiContainer);
      component.yotiFrame.src = '';
      component.removeFrameListener();
    }

    component.listenToFrame = () => {
      $window.document.addEventListener('yotiAgeVerificationResults', component.handleIframeEvent, false);
      $window.document.addEventListener('yotiAgeVerificationCloseModal', component.closeYotiModal);
    }

    component.removeFrameListener = () => {
      $window.document.removeEventListener('yotiAgeVerificationResults', component.handleIframeEvent, false);
      $window.document.removeEventListener('yotiAgeVerificationCloseModal', component.closeYotiModal, false);
    }

    component.generateYotiCookie = (id, result, expiry) => {
      document.cookie = `Verification=${result}/${id};Path=/;expires=${expiry}`;
      setTimeout(() => {
        component.closeYotiModal();
      }, 3000);
      component.successFlow();
    }

    component.removeAgeVerificationCookie = () => {
      document.cookie = 'Verification=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
    }

    component.errorFlow = () => {
      const yotiButtons = basketPage.querySelectorAll(component.config.selectors.yotiButton);
      const checkoutForms = basketPage.querySelectorAll(component.config.classNames.checkoutForm)
      const ageButtons = basketPage.querySelectorAll(component.config.classNames.verifyAgeButton);
      const paymentTypes = basketPage.querySelector(component.config.classNames.paymentTypes);

      //Remove Payment Types
      if(paymentTypes){
        paymentTypes.remove(component.config.classNames.paymentTypes);
      }
      
      //display error messaging
      component.ageVerificationError = basketPage.querySelector(component.config.classNames.ageVerificationError);
      component.ageVerificationError.classList.add(component.config.classNames.displayAgeError);

      //rendering age button and adding event listener
      Array.from(yotiButtons).map((el) => el.addEventListener('click', component.getYotiSession));
      Array.from(ageButtons).map((el) => el.classList.add(component.config.classNames.showAgeButton));

    }

    component.successFlow = () => {
      //remove age buttons
      const ageButtons = basketPage.querySelectorAll(component.config.classNames.verifyAgeButton);
      Array.from(ageButtons).map((el) => el.classList.remove(component.config.classNames.showAgeButton));

      //remove error, replace with success
      const errorMessage = basketPage.querySelector(component.config.classNames.ageVerificationError);
      if(errorMessage.classList.contains(component.config.classNames.displayAgeError)){
        errorMessage.classList.remove(component.config.classNames.displayAgeError);
      }
      basketPage.ageVerificationSuccess = basketPage.querySelector(component.config.classNames.ageVerificationSuccess);
      basketPage.ageVerificationSuccess.classList.add(component.config.classNames.displayAgeSuccess);

      //add action to form and remove disabled attribute from button
      const checkoutForms = basketPage.querySelectorAll(component.config.classNames.checkoutForm);
      const checkoutButtons = basketPage.querySelectorAll(component.config.classNames.checkoutButton);
      Array.from(checkoutForms).map((form) => form.setAttribute('action', '/checkoutStart.account'));
      Array.from(checkoutButtons).map((button) => button.removeAttribute('disabled'));
      component.addLinkToBanner();

    }

    component.addLinkToBanner = () => {
      let successLink = document.createElement('a');
      successLink.setAttribute('href', '/checkoutStart.account');
      successLink.innerText = siteObj.props.yoti.successLinkText

      const successContainer = basketPage.querySelector(component.config.classNames.successMessageContainer);
      successContainer.appendChild(successLink);

      //update the siteobj checkout URL
      siteObj.siteCheckoutURL = `${siteObj.siteSecureURL}checkoutStart.account`;

      if (siteObj.config.deleteVerificationCookie === true) {
        component.yotiCheckoutButton = basketPage.querySelector(component.config.classNames.yotiCheckout);
        const checkoutButtons = basketPage.querySelectorAll(component.config.classNames.checkoutButton);

        component.yotiCheckoutButton.addEventListener('click', component.removeAgeVerificationCookie);
        Array.from(checkoutButtons).map(el => el.addEventListener('click', component.removeAgeVerificationCookie));
      }
    }

    component.returnJsonObject = (data) => {
      if (typeof (data) === 'object') {
        return data;
      } else if (typeof (data) === 'string')
        data = JSON.parse(data);{
        component.returnJsonObject(data);
      }
    }

    // todo rename this function to something more meaningful
    component.handleIframeEvent = (data) => {

      let parseData  = data.detail ? JSON.parse(data.detail) : JSON.parse(data);
      parseData = JSON.parse(parseData);

      switch(parseData.status) {
        case 'COMPLETE':
          component.generateYotiCookie(parseData['id'], parseData['status'], parseData['expires_at']);
          break;
        default:
          component.errorFlow();
          // check modal exists before closing modal
          component.closeYotiModal();
          break;
      }
    }
    return component;
  };

  return yotiVerification;
});
